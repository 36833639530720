import React, {Component} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

class ReturnsPage extends Component {
  componentDidMount() {}
  render() {

    return (
      <Layout>
        <SEO title="Returns"/>

        <div id="privacy-page-wrapper">
          <img src={require('../images/returns/happy-blob.png')} style={{
            position: "absolute",
            top: "15%",
            right: 0,
            height: "500px",
            zIndex: "-1"
          }} alt=""/>
          <section className="section is-medium has-absolute-navbar">

            <div className="container">

              <div className="columns">
                <div className="column is-half">
                  <h1 className="title page-title">Returns</h1>
                  <p className="subtitle is-size-5">We strive to provide you with products that are the perfect fit for your health & lifestyle. We hope you’ll be completely satisfied with your products and will accept any full-sized products for a return (no exchanges), as long as it is at least half full and received within 30 days of initial purchase. If the item has been unreasonably tampered with, the original contents are not returned at least half-full, or shows signs of excessive wear and tear from user misuse, we reserve the right to deny a return.</p>
                  <br/>
                  <p>Prior to returning any item, we require that the customer must let us know that a return is on it’s way by contacting us at hello@reasontosmile.com. This is to ensure our staff is aware of an incoming item in a case where we don’t receive your package.</p>

                  <p>To return your product, please mail your product to:<br/>
                  1440 S. State College Blvd, Suite 5F<br/>
                  Anaheim, CA 92806.
                  </p>
                  <br/>
                  <p>
                    When returning by mail, you will be responsible for covering shipping costs to return items and shipping charges must be prepaid. Depending on your location, the shipping method and carrier used to return, your return package may take several days or weeks to reach us. Keep your proof of postage and ship your return along with a tracking number as we are not liable for return packages that are lost or stolen in-transit. Without proof of receipt and delivery, Reason to Smile will not issue a refund for your returns. Once your return is fully processed, we will issue your refund via the same manner of payment received. If you are shipping an item over $50, you should consider purchasing shipping insurance. Reason to Smile cannot guarantee that we will receive your returned item.</p>
                </div>
              </div>
            </div>

          </section>
          <section className="section" style={{
            position: "relative"
          }}>
            <img src={require('../images/returns/sunny-blob.png')} style={{
              position: "absolute",
              top: "-25%",
              left: 0,
              width: "408px",
              zIndex: "-1"
            }} alt=""/>
            <div className="container">

              <div className="columns">
                <div className="column"/>

                <div className="column is-half">
                  <h1 className="title page-title">Refunds</h1>
                  <p className="subtitle is-size-5">Upon receiving your returned item, a subsequent inspection will take place to better understand what may have gone wrong during shipping or elsewhere. We will send you an email to notify you that we have successfully received your item and will notify you on the status of your item and its approval or rejection for refund.</p>
                  <br/>
                  <p>
                    If approved, a credit equivalent to the amount paid will be applied to your original payment method within 30 days.</p>
                </div>
              </div>
            </div>

          </section>
          <section className="section is-medium">

            <div className="container">

              <div className="columns">
                <div className="column is-half">
                  <h1 className="title page-title">Late Refunds (if applicable)</h1>
                  <p className="">If we have past the 30 day refund window and you still cannot see a refund reflected in your statement, please double check your bank account.</p>
                  <br/>
                  <p>If you are still unable to find the refund reflected in your statement after double checking, please reach out to your credit card company as it typically takes some time before your refund is officially posted on behalf of the credit card company after we issue the credit back.
                  </p>
                  <br/>
                  <p>If there is still an issue, or you purchased through debit, please reach out to your bank as there is often a processing time with the bank before a refund is posted.
                  </p>
                  <br/>
                  <p>If all of this has been done, and a refund is still unavailable in your account, please reach out to us at hello@reasontosmile.com
                  </p>
                </div>
              </div>
            </div>

          </section>
          <section className="section" style={{
            position: "relative"
          }}>

            <div className="container">

              <div className="columns">
                <div className="column"/>

                <div className="column is-half">
                  <h1 className="title page-title">Cancellations</h1>
                  <p className="subtitle is-size-5">Orders cannot be cancelled once Reason to Smile has begun processing the order. In order to promptly deliver our products to you, the processing of an order commences almost immediately once you have submitted your order. However, should you wish to change or cancel your order, you are welcome to send an email to hello@reasontosmile.com to check whether the processing of an order has commenced, and if it has not, we may be able to accommodate your request to cancel and will do our best to meet your needs.</p>
                  <br/>
                  <p>If the order has not been shipped out yet, and we’re able to cancel it, we will provide a full refund to the original form of payment used to place the order. Accordingly, we encourage you to carefully check your order before submitting it.</p>
                  <br/>
                  <p className="has-text-weight-bold">For all inquiries, please contact </p>
                  <p className="">Reason to Smile at hello@reasontosmile.com</p>

                </div>
              </div>
            </div>

          </section>
          <section className="section" style={{
            position: "relative"
          }}>

            <div className="container">

              <div className="columns">

                <div className="column is-half">
                  <h1 className="title page-title">Domestic Shipping Policy </h1>
                  <p className="subtitle is-size-5">It is the responsibility of the customer to review our domestic shipping policy each time you order a Reason to Smile product to ensure you are up to date with any and all changes and/ or amendments. </p>
                  <br/>
                  <p className="has-text-weight-bold">Shipment processing time</p>

                  <p>Each order from Reason to Smile will be processed within 1-3 business days and are not processed, shipped, or delivered on weekends or holidays. </p>

                  <br/>
                  <p className="">On special occasions during times of high volume orders, processing and shipments can potentially be delayed by a number of days. Take this into consideration when allowing for additional days in transit for delivery. </p>
                  <br/>
                  <p className="has-text-weight-bold">Order Tracking</p>
                  <p>Allow for 2-5 business days for our item tracking to update. Upon shipment, you will receive email confirmation outlining your shipment details. If you did not receive this email, please check All Mail or any associated subsection of your email address as messages sometimes are delivered outside of Inbox. </p>

                  <br/>
                  <p className="">If you have reviewed your email thoroughly and still cannot find your shipment details, please contact us at hello@reasontosmile.com and we will provide you with your tracking number.</p>
                  <br/>
                  <p className="has-text-weight-bold">Suggestions for Re-Ordering</p>
                  <p className="">We suggest that our customers renew their order with us when their CBD is at about half to ensure consistent use without delay between orders. This will ensure you will not run out of CBD. </p>
                  <br/>
                  <p className="has-text-weight-bold">Unforeseen and/ or Unanticipated Events </p>
                  <p className="">Upon purchase, all customers agree not to hold Reason to Smile liable for failure to deliver our product for any and all reasons beyond our control. This includes, but is not limited to, acts of God, natural disasters, war, terrorism, riots, criminal acts, labor shortages, labor strikes (both lawful and unlawful), postal or courier service disruption, infrastructure disruption, communication failure, material shortages, or any and all other circumstances that are reasonably beyond our control. </p>
                  <br/>
                  <p className="has-text-weight-bold">Damages</p>
                  <p className="">We are not liable for any products damaged or lost during shipping. In an instance where you have received your order and the product is damaged, please contact the shipment carrier to file a claim. </p>
                  <br/>
                  <p className="">If you file a claim with the shipment carrier, we require you save all packaging material and damaged goods before, during, and after the filing. </p>


                </div>
                <div className="column"/>

              </div>
            </div>

          </section>
        </div>

      </Layout>
    )
  }
}

ReturnsPage.propTypes = {}

export default ReturnsPage
